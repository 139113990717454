* {
  border: 0;
  margin: 0;
}

body {
  overflow-x: hidden;
}

.App {
  background-color: #000;
  font-family: "Poppins";
}

/* Scroll Bar */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #000; 
}
 
::-webkit-scrollbar-thumb {
  background: #666;
  box-shadow: 0 0 4px #000;
}

::-webkit-scrollbar-thumb:hover {
  background:	#666;
}